.SuccessContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Logo {
  display: flex;
  margin: 24px 0;
}

.HeaderSuccess {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 203px;
  background-color: #eef9fc;
}

.HeaderTitle {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-left: 30px;
  margin-top: 60px;
  max-width: 195px;
}

.TextTransaction {
  max-width: 76px;
}

.HeroSuccess {
  width: 165px;
  height: 200px;
  align-self: flex-end;
  margin-right: 30px;
}

.Amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.AddressForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FormSubmitButton {
  outline: none;
  margin-top: 30px;
  width: 156px;
}

.SubmitButton {
  outline: 0;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  padding: 14px 30px 15px;
  font-family: 'ModernEra';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

.TitleAddress {
  font-family: 'ModernEra';
  font-size: 16px;
}

.Bold {
  font-family: 'ModernEraBold';
}

.Transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 18px;
  max-width: 338px;
}

.Transaction > p {
  font-family: ModernEraBold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Item {
  margin: 0;
  font-family: 'ModernEraBold';
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.TransactionId {
  border-radius: 10px;
  background-color: #e4e4e4;
}

.TransactionId > p {
  margin: 11px 10px;
  font-family: ModernEraBold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.CenterItem {
  border-radius: 10px;
  border: solid 2px #003fff;
  background-color: #ffffff;
  padding: 16px 19px;
  width: 100%;
  max-width: 300px;
  margin-top: 16px;
}

.TitleProduct {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.PriceOldTemp {
  margin: 8px 0 0;
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  text-decoration: line-through;
}

.Price {
  margin: 6px 0 0;
  font-family: ModernEraBold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Buy {
  width: 100%;
  max-width: 338px;
  margin: 25px 0 0;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.TitleCouponContainer {
  display: flex;
  margin-top: 17px;
  flex-direction: column;
  align-items: center;
}

.PendingSuccess {
  display: flex;
  align-items: center;
  width: 100%;
  height: 109px;
  background-color: #fceed2;
  justify-content: center;
  z-index: 2;
}

.PendingTitle {
  max-width: 261px;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 0 10px 0 26px;
}

.Clock {
  width: 37.9px;
  height: 37.6px;
  margin-left: 20px;
}

@media (max-width: 350px) {
  .Transaction {
    max-width: 300px;
  }

  .CenterItem {
    max-width: 262px;
  }

  .HeaderTitle {
    margin-left: 20px;
  }

  .HeroSuccess {
    margin-right: 10px;
  }

  .PendingTitle {
    font-size: 14px;
  }

  .Item {
    font-size: 18px;
  }
}

@media (min-width: 700px) {
  .Wrapper {
    max-width: 450px;
  }

  .HeaderTitle {
    margin-right: 20px;
  }

  .Transaction {
    justify-content: space-around;
  }

  .TextTransaction {
    max-width: 154px;
  }
}
