.AddressContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RowGroup2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Col1 {
  width: 48%;
  margin-right: 2%;
}
.Col2 {
  width: 48%;
  margin-left: 2%;
}
