.HeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Center {
  justify-content: center;
}

.BackLogoContainer {
  display: flex;
  width: 100%;
}

.AllWith {
  width: 100%;
}

.PartWith {
  width: 80%;
}

.HeaderContainer.Right {
  display: none;
}

.PrincipalProducts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 345px;
  width: 100%;
}

.WrapperPrice {
  display: flex;
  margin: 15px 0;
}

.ProductsToPurchase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 345px;
  width: 100%;
}

.AskProducts {
  margin-top: 15px;
  font-family: 'ModernEraBold';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.Back {
  margin: 42px 0 0 15px;
  cursor: pointer;
  outline: none;
}

.Logo {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DisplayNone {
  display: none;
}

@media (min-width: 700px) {
  .HeaderContainer.Right {
    display: flex;
  }

  .HeaderContainer {
    display: none;
  }

  .BackLogoContainer {
    width: 110%;
  }

  .Logo {
    margin-top: 80px;
  }

  .PrincipalProducts {
    flex-direction: row;
    max-width: 494px;
  }

  .ProductsToPurchase {
    display: none;
  }
}

@media (max-width: 350px) {
  .PartWith {
    width: 70%;
  }

  .PrincipalProducts {
    max-width: 290px;
  }

  .ProductsToPurchase {
    max-width: 290px;
  }
}
