.CardDataContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.RowGroup3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.InstallmentsText {
  margin: 0;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-family: 'ModernEra';
  align-self: center;
  text-align: center;
  max-width: 300px;
}

.Col1 {
  display: flex;
  width: 100%;
  margin-right: 2%;
  order: 1;
}
.Col2 {
  width: 48%;
  margin-right: 1%;
  order: 2;
}
.Col3 {
  width: 48%;
  margin-left: 1%;
  order: 3;
}
.Col4 {
  width: 100%;
  margin-left: 1%;
  order: 4;
}

.McAffe {
  width: 90px;
  height: 37px;
  border-bottom: 2px solid #c8ccd4;
  align-self: flex-end;
  border-bottom: 2px solid #c8ccd4;
  padding: 3px;
  margin-bottom: 10%;
}

.McAffeError {
  width: 90px;
  height: 37px;
  border-bottom: 2px solid #c8ccd4;
  align-self: flex-end;
  border-bottom: 2px solid #c8ccd4;
  padding: 3px;
  margin-bottom: 15.3%;
}

.DniSelector {
  margin-top: 20px;
  width: 100%;
}
.devolution {
  font-family: 'Modern Era', sans-serif;
  text-align: center;
  font-size: 14px;
}

@media (min-width: 700px) {
  .Col1 {
    width: 100%;
    font-size: 10px!;
  }
  .Col2 {
    width: 32%;
    margin-right: 2%;
    font-size: 10px!;
  }
  .Col3 {
    width: 32%;
    font-size: 10px!;
  }
  .Col4 {
    width: 30%;
    margin-left: 3%;
    font-size: 10px!;
  }
}
