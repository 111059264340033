.AddressContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 10px 10px 20px 0 #cfcfcf;
  background-color: #ffffff;
  margin-top: 15px;
  margin-bottom: 100px;
  width: 320px;
}

.Title {
  font-family: 'ModernEraBold';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
  margin: 15px 15px 10px;
}

.Address {
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #92979c;
  margin: 0 15px 15px;
}
