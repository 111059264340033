.textWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mainText {
  margin-left: 5px;
}
.logoPromo {
  height: 24px;
}
.hot {
  font-family: ModernEraBold, sans-serif;
}

.textCenter {
  text-align: center;
}

@media (min-width: 766px) {
  .textWrapper,
  .mainText {
    display: flex;
    align-items: center;
  }
  .mainText {
    margin-left: 5px;
  }
  .logoPromo {
    height: 24px;
    margin-left: 3px;
  }
}
