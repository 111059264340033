.SuccessContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Logo {
  display: flex;
  margin: 24px 0;
}

.HeroSuccess {
  width: 100%;
}

.Amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 23.5px;
}

.LoaderError {
  margin-top: 100px;
}

.Error {
  color: red;
  margin: 0 10px 0;
  text-align: left;
  font-family: 'ModernEra';
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  text-align: center;
}

.AddressForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FormSubmitButton {
  outline: none;
  margin-top: 30px;
  width: 156px;
}

.SubmitButton {
  outline: 0;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  padding: 14px 30px 15px;
  font-family: 'ModernEra';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

.TitleAddress {
  margin-top: 20px;
  font-family: 'ModernEra';
  font-size: 16px;
}

.TitleAddressSuccess {
  margin-top: 20px;
  font-family: 'ModernEra';
  font-size: 16px;
}

.TitleAddressLink {
  margin-top: 20px;
  font-family: 'ModernEra';
  font-size: 16px;
  color: #0752f9;
  outline: none;
  cursor: pointer;
}

.Bold {
  font-family: 'ModernEraBold';
}

.Item {
  margin: 0;
  font-family: 'ModernEraBold';
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.TitleCouponContainer {
  display: flex;
  margin-top: 17px;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 700px) {
  .Wrapper {
    max-width: 450px;
  }
}
