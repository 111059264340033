.CouponContainer {
}

.Wrapper {
  display: flex;
  margin: 10px 0;
}

.Separator {
  border: solid 1px #e0e0e0;
  margin: 20px 0;
  width: 100%;
  max-width: 345px;
}

.SeparatorFooter {
  display: none;
  border: solid 1px #e0e0e0;
  margin: 0;
  width: 100%;
  max-width: 345px;
}

.Form {
  margin: 0 !important;
}

.AddCupon {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  align-items: center;
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0752f9;
  outline: none;
  cursor: pointer;
}

.TextCoupon {
  width: auto;
  display: flex;
  background-color: #d8d8d8;
  padding: 10px 10px 6px;
  text-align: start;
}

.TagCoupon {
  display: flex;
  margin: 0 0 10px;
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.DeleteCoupon {
  font-family: 'ModernEraBold';
  font-size: 15px;
  color: #2a2a2a;
  outline: none;
  cursor: pointer;
  margin-left: 12px;
}

.Rectangle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  border: solid 1px #0752f9;
  cursor: pointer;
  outline: none;
}

.ArrowRight {
  margin-left: 10px;
  height: 10px;
  width: 32px;
}

.ArrowBlue {
  height: 16px;
  width: 32px;
}

.Input {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: solid 1px #2a2a2a;
  font-size: 16px;
  max-width: 285px;
  width: 80%;
  padding-bottom: 11px;
  margin-right: 15px;
  outline: none;
}

::placeholder {
  font-family: 'ModernEra';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
}

.Hide {
  display: none;
}

@media (min-width: 700px) {
  .Separator {
    display: none;
  }

  .SeparatorFooter {
    display: flex;
  }
}
