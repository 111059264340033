.CounterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 10px;
  border-radius: 11px;
  border: solid 1px #4971a8;
  background-color: #e9f2fe;
  font-family: 'ModernEra';
  font-size: 13px;
  color: #4971a8;
  max-width: 260px;
}

.Discount {
  color: #b92835 !important;
}

.Counter {
  margin-left: 5px;
  font-family: 'ModernEraBold';
}

@media (min-width: 350px) {
  .CounterContainer {
    padding: 15px 16px;
  }
}

@media (min-width: 380px) {
  .CounterContainer {
    padding: 15px 36px;
  }
}
