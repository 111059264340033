.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 90vh;
  width: 100wh;
}

.SealMoon {
  position: relative;
  top: -112px;
  left: 14px;
  height: 93px;
  width: 93px;
  background: rgba(232, 196, 252, 1);
  background: -moz-linear-gradient(
    top,
    rgba(232, 196, 252, 1) 0%,
    rgba(153, 190, 252, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(232, 196, 252, 1)),
    color-stop(100%, rgba(153, 190, 252, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(232, 196, 252, 1) 0%,
    rgba(153, 190, 252, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(232, 196, 252, 1) 0%,
    rgba(153, 190, 252, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(232, 196, 252, 1) 0%,
    rgba(153, 190, 252, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(232, 196, 252, 1) 0%,
    rgba(153, 190, 252, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8c4fc', endColorstr='#99befc', GradientType=0 );
  border-radius: 50%;
}
.SealMoon > img {
  position: absolute;
  top: 30px;
  left: 26px;
}

.SealRing > img {
  height: 123px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ContainerMessage {
  font-family: 'ModernEra';
  padding: 0px 20px;
}

@media (min-width: 800px) {
  .ContainerMessage {
    width: 480px;
    height: 72px;
    font-size: 20px;
  }
}

@media (max-width: 799px) {
  .ContainerMessage {
    height: 100px;
    font-size: 16px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
