.CounterContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 11px;
  font-family: 'ModernEraBold', sans-serif;
  color: white;
}
.margin {
  margin-top: 4px;
}
.number {
  width: 29px;
  height: 25px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 19px;
  color: #1414b3;
  margin-bottom: 3px;
}
.symbol {
  font-size: 19px;
  color: white;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.daysWrapper {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.Subtitle {
  width: 100%;
  font-size: 8px;
  display: flex;
  justify-content: space-between;
}

.dateTexts {
  margin: 2px auto 0;
}

@media (min-width: 951px) {
  .CounterContainer {
    width: auto;
    margin: 0 auto;
  }
  .Subtitle {
    font-size: 10px;
  }
  .daysWrapper {
    margin-right: 6px;
  }
  .wrapper {
    min-width: 42px;
  }
}
