.TotalPrice {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 345px;
}

.Price {
  margin: 0;
  font-family: 'ModernEraBold';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #252525;
}

.PriceContainer {
  display: flex;
  justify-content: space-between;
}

.Iva {
  margin: 7px 0 0;
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

@media (min-width: 700px) {
  .TotalPrice {
    display: none;
    max-width: 345px;
  }
}

@media (max-width: 350px) {
  .Iva {
    font-size: 11px;
  }

  .Price {
    font-size: 18px;
  }
}
