.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SmallCircle {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

.BigCircle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Line {
  width: 45px;
  height: 2px;
}

.Blue {
  background-color: #99bffc;
}

.White {
  background-color: white;
}

.Gray {
  background-color: gray;
}
