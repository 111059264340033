.OxxoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'ModernEra';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2a2a2a;
  margin-top: 30px;
  max-width: 350px;
}

.OxxoReference {
  margin: 0;
}

.OxxoTitle {
  margin: 16px 0 0 0;
}

.Notice {
  margin: 20px 0 0 0;
}

.RefItem {
  margin: 16px 5px 0 5px;
  border-radius: 8px;
  background-color: #ffe8c5;
  padding: 15px 8px;
}

.RefContainer {
  display: flex;
}

.Barcode {
  margin-top: 30px;
  width: 50%;
}

.Buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ButtonDownload {
  border-radius: 8px;
  border: solid 1px #0752f9;
  padding: 14px 30px;
  margin-top: 30px;
  cursor: pointer;
}

.ButtonSend {
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #0752f9;
  padding: 14px 30px;
}

@media (max-width: 350px) {
  .OxxoContainer {
    width: 300px;
  }
  .RefContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
}
