.ExtraContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
  width: 100%;
  margin-top: 30px;
  outline: none;
}

.TextTema {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: 'ModernEraBold';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
  outline: none;
}

.SelectorWrapper {
  outline: none;
}

.Arrow {
  display: flex;
  width: 15px;
  height: 16px;
}

.ItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.ImageProducts {
  width: 68px;
  height: 68px;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.MarginImage {
  margin-right: 15px;
}

.Left {
  display: flex;
}

.TitlePrice {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.NameProduct {
  margin: 0;
  font-family: 'ModernEraBold';
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.Price {
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

@media (min-width: 700px) {
  .Arrow {
    display: none;
  }

  .TextTema {
    justify-content: center;
  }
}
