.PhotoLinkContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  max-width: 414px;
}

.Header {
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
}

.SaveTime {
  margin: 0;
  font-family: EnglandSignature;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: right;
  color: #1e1e1e;
}

.Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fceed2;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 32px 38px;
}

.CircularArrow {
  margin: 10px 7px 0 0;
}

.Title {
  margin: 24px 0 0;
  font-family: QuincyCFBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #292929;
}

.Desc {
  margin: 32px 0 0;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #292929;
}

.SubmitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
  max-width: 311px;
  height: 48px;
  border-radius: 10px;
  background-color: #0852fa;
  outline: none;
  cursor: pointer;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 350px) {
  .SaveTime {
    font-size: 27px;
  }
}

@media (min-width: 400px) {
  .Header {
    margin-right: 6%;
  }
}

@media (min-width: 700px) {
  .Header {
    margin-right: 8%;
  }
}
