.Container,
.ContainerWithoutCounter {
  height: auto;
  z-index: 2000;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  background-color: #1414b3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ContainerWithoutCounter {
  height: 86px;
}
.margin {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  height: 100%;
}

.ImageMobile {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.secondText {
  display: flex;
  height: 35px;
  justify-content: center;
}

.Wrapper {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.wrapperCounter {
  width: 70%;
  max-width: 283px;
  margin: 2px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  bottom: 0;
}

.badgeDesktop,
.sevenMarDesktop {
  display: none;
}

.Coupon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Coupon > p {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.ContainerCoupon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  padding: 4px 11px;
  border-radius: 5px;
  border: solid 1px #707070;
  background-color: #ffffff;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Valid {
  display: none;
  font-family: ModernEra;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #101d3b;
  margin-left: 6px;
}

.ByeBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  position: fixed;
  z-index: 2000;
  width: 100%;
  overflow: hidden;
}

.Birth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 97px;
  z-index: 3000;
}

.textPromo {
  width: 100%;
  z-index: 100;
  color: white;
  margin-top: 5px;
}

.firstText {
  text-align: center;
  font-size: 15px;
}

.price,
.priceMx {
  margin-left: 10px;
  font-size: 26px;
  font-family: ModernEraBold, sans-serif;
}

.priceMX {
  margin-left: 30px;
}

.off {
  font-family: ModernEra, sans-serif;
  font-size: 26px;
  margin: 5px 0 0 5px;
}

.nowText {
  font-family: ModernEraBold, sans-serif;
  color: white;
}

.complyText {
  font-family: ModernEra, sans-serif;
  color: white;
}

.treatment,
.treatmentWithOutCounter {
  font-family: ModernEra, sans-serif;
  font-size: 15px;
  text-align: center;
}

.treatmentWithOutCounter {
  font-size: 21px;
}

.dateBadge,
.dateBadgeDesktop {
  height: auto;
  width: 44px;
  align-self: start;
  z-index: 100;
  margin-right: 12px;
  margin-top: 3px;
}

.dateBadgeDesktop {
  display: none;
}

.Birth > p {
  font-family: ModernEra;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 5px 0 0;
  max-width: 54px;
}

.WrapperTimer {
  display: block;
}

.HeartRight {
  z-index: 3000;
  height: 80px;
  display: none;
}

.HeartLeft {
  z-index: 3000;
  height: 80px;
  transform: rotate(15deg);
  display: none;
}

.ByeBanner .ImageDesktop {
  display: flex;
}

.ImageDesktop {
  display: none;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: fixed;
  width: 100%;
}

.ByeText {
  font-family: ModernEra;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #101d3b;
  margin: 0;
}

.ByeText > span {
  color: #ffc0d4;
  font-family: QuincyCF;
  font-size: 28px;
}

.OffText {
  font-family: ModernEraBold, sans-serif;
  font-size: 19px;
  text-align: left;
  color: #ffffff;
  margin: 9px 0 0 0;
}

.Off {
  font-family: ModernEra;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-left: 4px;
}

.Rows {
  display: flex;
  margin-top: 7px;
}

.CovidContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2000;
  min-height: 62px;
}

.CovidBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  width: 100%;
  min-height: 62px;
  background-color: #fafdfe;
}

.CovidText {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  max-width: 200px;
  margin: 11px 0 11px 15px;
}

.CovidLink {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
  margin-left: 10px;
}

.CovidLogo {
  width: 33.7px;
  height: 24.7px;
  margin-left: 10px;
}

.BannerMobile,
.BannerDesktop,
.BannerMobileBlack,
.BannerDesktopBlack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  width: 100%;
  height: 80px;
}

.BannerMobileBlack > .TextBar {
  flex-direction: column;
}

.BannerMobile,
.BannerDesktop {
  background-color: #ed1c24;
}

.BannerMobileBlack,
.BannerDesktopBlack {
  background-color: #101d3b;
}

.BannerMobile,
.BannerMobileBlack {
  display: flex;
}

.BannerDesktop,
.BannerDesktopBlack {
  display: none;
}

.Banner > img {
  margin-left: 20px;
}

.TextBarDesk {
  display: flex;
  align-items: center;
}

.TextBarDesk > p,
.Talk {
  font-family: ModernEraBold;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Logo {
  width: 36.5px;
  height: 36.5px;
}

.BlackFLogo {
  width: 140px;
  height: 30px;
  margin-bottom: 4px;
}

.DiscountText,
.TextBarTwo {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.DiscountText,
.TextBarTwo {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.BannerMobileBlack > .TextBar > .DiscountText,
.BannerMobileBlack > .TextBarTwo,
.BannerMobileBlack > .TextBarTwo > .DiscountText,
.BannerDesktopBlack .Talk,
.BannerDesktopBlack .TextBarDesk > p {
  color: #94b7fe;
  line-height: 1.33;
}

.Talk {
  margin-left: 8px;
}

.TextBarDesk > .Logo {
  width: 36.5px;
  height: 36.5px;
  margin-right: 8px;
}

.TextBar {
  display: flex;
  align-items: center;
}

.DiscountText {
  margin-left: 10px;
}

.wrapperTimer {
  width: 100%;
}

@media (min-width: 767px) {
  .Container,
  .ContainerWithoutCounter {
    height: 60px;
  }
  .margin {
    flex-direction: row;
  }
  .wrapperTimer {
    width: 300px;
  }
  .treatment,
  .treatmentWithOutCounter {
    font-family: ModernEra, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    margin: -4px 0 3px 0;
  }
}
