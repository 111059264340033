.ContainerCounter {
  display: flex;
  height: 35px;
  border-radius: 7px;
  box-shadow: inset 0 0 20px 0 rgba(169, 192, 221, 0.4);
  background-color: #ffffff;
}

.FormCounter {
  box-sizing: border-box;
  padding: 8px 0;
  width: 30px;
  height: 33px;
  outline: none;
  border: none;
  opacity: 0.5;
  font-family: 'ModernEraBold';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2a2a2a;
}

.FormCounterButtom {
  box-sizing: border-box;
  font-family: 'ModernEraBold';
  font-size: 16px;
  padding: 9px 0;
  width: 36.5px;
  height: 33px;
  text-align: center;
  color: #0752f9;
  outline: none;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
