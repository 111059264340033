.PrincipalContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 17px;
  outline: none;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'ModernEra';
  border: 2px solid #ffffff;
  cursor: pointer;
}

.DescHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.DeleteProduct {
  font-family: 'ModernEraBold';
  font-size: 15px;
  margin: 0;
  color: #2a2a2a;
  outline: none;
  cursor: pointer;
}

.Prices {
  display: flex;
  flex-direction: column;
}

.Row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CouponText {
  margin-top: 15px;
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #99bffc;
}

.Description {
  font-size: 16px;
  font-family: 'ModernEraBold';
  color: #2a2a2a;
  margin: 0;
}

.LastPrice {
  margin-top: 15px;
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  color: #6d7278;
}

.Row .LastPrice {
  margin: 0 10px 0 0;
}

.ImageCoupon {
  margin-right: 5px;
}

.TotalPrices {
  font-family: 'ModernEraBold';
  margin-top: 1px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.35;
  color: #252525;
}

.TextInstallments,
.TextDevolution {
  margin-top: 1px;
  font-size: 13px;
  font-weight: normal;
  color: #6d7278;
}

.TextDevolution {
  margin-top: 15px;
}

.Selected {
  border: 2px solid #0752f9;
}

@media (min-width: 700px) {
  .PrincipalContainer {
    margin-left: 10px;
    width: 202px;
  }

  .TotalPrices {
    font-size: 20px;
  }
}
