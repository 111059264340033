.FormsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 345px;
  width: 100%;
}

.HeaderForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 43px;
}

.TitleSteps {
  margin: 20px 0 15px;
  font-family: 'ModernEraBold';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.InstallmentsText {
  margin: 30px 0 0;
  font-family: 'ModernEra';
  font-size: 14px;
}

.DisplayNone {
  display: none;
}

@media (max-width: 350px) {
  .FormsContainer {
    max-width: 305px;
  }
}
