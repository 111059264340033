.PaymentSelector1Container {
  width: 100%;
  font-family: 'ModernEra';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #252525;
}

.ThemesMobileContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 30px;
}

.TopicSelector {
  display: flex;
  flex-direction: column;
  outline: none;
  width: 350px;
  border-radius: 21px;
  box-shadow: inset 0 0 20px 0 rgba(169, 192, 221, 0.4);
}

.PaymentMethosImg {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 15px 0 25px;
  width: 100%;
}

.Express {
  border-radius: 11px;
  border: solid 1px #6d7278;
  padding: 13px 13px 13px 3px;
}

.ExpressImg {
  margin-bottom: 5px;
}

.ItemExpress {
  margin-left: 10px;
}

.TitleExpress {
  margin-bottom: -20px;
  margin-left: 10px;
  background: white;
  font-family: 'ModernEra';
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6d7278;
}

.TextTema {
  height: 58px;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0 5%;
  cursor: pointer;
}

.TextTema > img {
  margin-left: auto;
}

.TopicSelector > ul,
.TopicDesktopSelector > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  transition: max-height 0.5s ease 0s;
}
.TopicSelector > ul > li,
.TopicDesktopSelector > ul > li {
  padding: 8px 12px;
  transition: max-height 0.5s ease 0s;
}

.TopicSelector > ul > li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.ItemPayments {
  margin-left: 15px;
  width: 50px;
  height: 23px;
}

.visa {
  margin-left: 0;
}

.dinnersClub {
  height: 18px;
  width: 65px;
  filter: grayscale(1); /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
}

.redCompra {
  height: 18px;
  width: 65px;
  filter: grayscale(1); /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
}

@media (max-width: 350px) {
  .TopicSelector {
    width: 300px;
  }

  .ItemPayments {
    width: 40px;
    height: 20px;
  }
}

@media (min-width: 800px) {
  .TopicSelector {
    width: 407px;
  }
}
