.FooterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  width: 100%;
  padding-bottom: 100px;
}

.ImagesContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 345px;
  align-self: center;
  margin-bottom: 30px;
  align-items: center;
  position: relative;
}

.Badge {
  position: absolute;
  right: -7px;
  bottom: 67%;
  width: 100px;
}

.ItemLove {
  margin-top: -1px !important;
  margin-bottom: 10px !important;
}

.SendImages {
  width: 138px;
  height: 51px;
}

.ItemLove > p {
  margin: 0;
}

.LinksContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  background-color: #ededed;
  padding: 30px 15px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}

.Bold {
  font-family: 'ModernEraBold';
}

.ItemStripe,
.ItemTerm,
.ItemPrivacy,
.ItemLove {
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d7278;
  margin: 0;
  text-align: center;
  text-decoration: none;
}

.ItemStripe {
  align-items: center;
  margin-top: 10px;
}

.LinksLeft {
  display: flex;
  justify-content: space-between;
}

.McAffe {
  width: 120px;
}

@media (min-width: 700px) {
  .Badge {
    position: absolute;
    right: -38px;
    bottom: 92%;
    width: 122px;
    z-index: 1;
  }

  .ImagesContainer {
    max-width: 494px;
    margin-bottom: 0px;
  }

  .LinksContainer {
    background-color: transparent;
    flex-direction: row;
    justify-content: flex-start;
  }

  .LinksLeft {
    justify-content: none;
  }

  .ItemStripe,
  .ItemTerm,
  .ItemPrivacy {
    margin-right: 15px;
  }

  .ItemStripe {
    margin-right: 15px;
    margin-top: 0;
  }

  .ItemTerm {
    order: 1;
  }

  .ItemPrivacy {
    order: 2;
  }
}

@media (max-width: 350px) {
  .McAffe {
    width: 110px;
  }

  .GarantyImages {
    width: 50px;
  }
}
